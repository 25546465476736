/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.EquipmentDetail {
  width: max-content;
  border: 1px solid #dedede;
  padding: 16px;
  width: 95%;
  max-width: calc(100% - 260px);

  .product_sheet_preview {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300px;
  }

  .product_sheet_image {
    width: 100%;
    height: 70px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    border-radius: 15px;
    display: inline-block;
    height: 80px;
    width: 150px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0 !important;
    }
  }

  @include desktop {
    width: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    &__titles {
      & > h4 {
        margin: 0;
        font-size: 16px;
        font-weight: normal;
      }
      & > h3 {
        font-size: 20px;
      }
    }

    &__actions {
      display: flex;

      &__action {
        color: #062746;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        & > svg {
          border: 1px solid #062746;
          border-radius: 50%;
          padding: 5px;
          margin-bottom: 5px;

          &:hover {
            background-color: #f1f8ff;
          }
        }
      }
    }

    &__add {
      display: flex;
      color: #062746;
      text-align: right;
      border-top: none;
      cursor: pointer;
      cursor: pointer;
      align-items: center;

      span {
        display: none;
        @include desktop {
          display: block;
        }
      }
      svg {
        font-size: 16px;
        margin-bottom: -3px;
        margin-right: 3px;
      }
    }
  }

  &__image {
    background: url('https://source.unsplash.com/DpPutJwgyW8');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300px;
  }

  &__dates {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    &__block {
      display: flex;
      flex-direction: column;
      text-align: center;

      &__date {
        font-size: 16px;
        color: #062746;
        font-weight: bold;
      }

      &__text {
        font-weight: 300;
        font-size: 12px;
        max-width: 65px;
        overflow-wrap: normal;
      }
    }
  }
  &__model {
    & > div {
      font-size: 14px;
    }
  }
  &__techniques {
    & > div {
      font-size: 14px;
    }
  }
  &__comment {
    & > p {
      overflow-wrap: normal;
      max-width: 500px;
      font-size: 14px;
    }
  }
  &__documents {
    display: flex;
    flex-wrap: wrap;
    &__title {
      width: 100%;
      text-transform: uppercase;
    }
    &__document {
      height: 90px;
      width: 230px;
      display: flex;
      //flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(111, 181, 19, 0.1);
      box-sizing: border-box;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-right: 20px;
      cursor: pointer;
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f1f8ff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 20px;
      }
      &__file {
        font-weight: normal;
        font-size: 12px;
        color: #062746;
      }
    }
  }

  &__events {
    width: 100%;
    &__title {
      text-transform: uppercase;
    }
    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__add {
        display: flex;
        color: var(--green-dark);
        text-align: right;
        border-top: none;
        cursor: pointer;
        cursor: pointer;
        align-items: center;
      }

      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__icon {
          align-self: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__title {
          align-self: center;
          display: flex;
          min-width: 200px;

          &__titles {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
          &__top {
            font-size: 12px;
            margin: 0;
          }

          &__main {
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            margin: 0;
          }
        }

        &__dates {
          align-self: center;

          font-size: 12px;
        }

        &__actions {
          align-self: center;

          & > svg {
            height: 16px;
          }
        }
      }
    }
  }
}
