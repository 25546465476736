/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.Breadcrumb {
    a {
      &::after {
        content: '';
        z-index: 0;
        display: inline-block;
        border-right: 2px solid var(--black);
        border-bottom: 2px solid var(--black);
        width: 8px;
        height: 8px;
        margin: 0 12px;
        transform: translate(-50%, 0%) rotate(-45deg);
      }
    }
  }
