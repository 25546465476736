/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.Gallery {
  img {
    // max-width: 1200px;
    width: auto;
  }
  .thumbnail {
    max-width: 100px;
  }
  .active-thumbnail {
    border-color: #f17633;
  }
  .dot.active {
    background-color: #f17633;
    outline: 1px solid #062746;
    outline-offset: -1px;
  }

  .dot {
    background-color: #062746;
  }

  .image-gallery-svg {
    width: 30px !important;
  }
  .image-gallery-slides {
    // max-height: 70vh !important;
  }
  .image-gallery-thumbnail.active {
    border-color: #f17633;
  }
  .image-gallery-bullet.active {
    background-color: #f17633 !important;
  }
}
