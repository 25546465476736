/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.MapLoader {
  width: 100%;
  height: calc(100% - 15px);
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e01c;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.marker-container {
  border-radius: 15px;
  border-color: rgb(6, 39, 70);
  border-width: 3px;
  border-style: solid;
  background-color: white;
  width: 20px;
  height: 20px;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  opacity: 1;
  pointer-events: all;
}

.highlighted-marker-container {
  border-radius: 10px;
  border-width: 1px;
  width: 160px;
  height: 160px;
}

.unhighlighted-marker-container {
  border-radius: 0px;
  border-width: 0px;
  width: 0px;
  height: 0px;
  box-shadow: none;
  opacity: 0;
  pointer-events: none;
}

.logoMarker {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 10px;
  height: 10px;
  margin: 7px;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  background-color: rgb(241, 118, 51);
  opacity: 1;
}

.highlighted-logoMarker {
  width: 0px;
  height: 0px;
  left: -7px;
  top: -7px;
  opacity: 0;
}

.address {
  opacity: 0;
  width: 0px;
  height: 0px;
  pointer-events: none;
  padding: 6px;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 10;
  }
}

.highlighted-address {
  width: auto;
  animation: fadein 1s;
  animation-fill-mode: forwards;
}

.imgProduct {
  width: 0px;
  height: 0px;
  margin: 4px;
  transition: all 0.5s ease-in-out;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
}

.highlighted-img-product {
  width: 150px;
  height: 90px;
}

.gm-style iframe + div {
  border: none !important;
}
.gm-style-iw button:focus {
  outline: 0;
}
