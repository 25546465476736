/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.textDropZoneArea {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #c4c4c4;
}

.MuiDropzoneArea-root {
  border-radius: 20px !important;
  border: 0px dashed !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='15' stroke-linecap='butt'/%3e%3c/svg%3e");
  padding: 20px !important;
  stroke-dasharray: 10 !important;
}


.MuiDropzonePreviewList-removeButton{
  top: 0px !important;
  right: 0px !important;
  position: inherit;
}

.MuiDropzonePreviewList-imageContainer.MuiGrid-item{
  padding-top: 0px !important;
  padding-left: 0px !important;
}

.MuiDropzonePreviewList-root{
  display: contents !important;

}


