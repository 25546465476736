/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

@import 'src/styles/app';

.Auth {
  max-width: 400px;
  //border: 1px solid black;
  width: 400px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
  padding: 20px;
  background-color: white;
  margin: 20px;
  &__header {
    text-align: center;
    & > img {
    }

    & > h1 {
      font-weight: normal;
      color: var(--gray-dark);
    }
  }
  &__linkCGV {
    text-decoration: underline;
  }

  &__grid {
    @include desktop {
      display: flex;
      width: 100%;

      & > div {
        margin-bottom: 0;
        &:last-child {
          margin-left: 5px;
        }
        &:first-child {
          margin-right: 5px;
        }
      }
      input {
        width: 80%;
      }
    }
  }

  &__submit {
    text-align: center;
    margin-top: 20px;

    & > a {
      font-size: 13px;
      color: var(--gray);
    }
  }

  &__hr {
    position: relative;
    & > hr {
      border: 0;
      height: 1px;
      background: var(--gray-light);
      margin: 20px 0;
    }

    & > h3 {
      font-weight: normal;
      color: var(--gray-dark);
      text-align: center;
    }

    &__or {
      position: absolute;
      color: var(--gray-light);
      top: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: var(--white);
      padding: 0 5px;
    }
  }
}
