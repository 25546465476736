/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.Product {
  width: 100%;

  &__breadcrumb {
    a {
      text-decoration: underline;
      &::after {
        content: '';
        z-index: 0;
        display: inline-block;
        border-right: 2px solid var(--black);
        border-bottom: 2px solid var(--black);
        width: 8px;
        height: 8px;
        margin: 0 12px;
        transform: translate(-50%, 0%) rotate(-45deg);
      }
    }
  }
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  &__link {
    display: flex;
    // text-align: right;
    cursor: pointer;
    span {
      display: none;
      @include desktop {
        display: block;
      }
    }
    svg {
      font-size: 16px;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }

  &__lineBlock {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    color: var(--black);

    @include desktop {
      font-size: 14px;
    }

    &__title {
      font-weight: bold;
      text-transform: uppercase;
      & > a {
        color: var(--black);
      }
    }
  }

  &__grid {
    flex: 1 1 70%;
    margin: 20px auto;
    height: 300px;
    display: flex;
    flex-direction: column;
    @include tablet {
      height: 500px;
    }
    @include desktop {
      flex-direction: row;
      height: 300px;
    }

    &__options {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 10px;
      @include desktop {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 20px;
      }
      & > div {
        background-color: cadetblue;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        cursor: pointer;
      }
    }
  }

  &__card {
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

    &__content {
      margin-top: 20px;
      font-size: 14px;
      a {
        color: var(--black);
        text-decoration: underline;
      }
      .MuiGrid-item {
        display: flex;
        align-items: center;
        svg {
          // margin-right: 15px;
        }
      }
    }

    &__element {
      display: flex;
      align-items: center;
      margin: 15px 0;
    }

    &__moreInfo {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
      & > div {
        margin-top: 15px;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 20px 20px;
      margin-top: 20px;

      @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 20px;
        border: 2px solid;
        height: 150px;
        font-weight: bold;
        cursor: pointer;

        & > div {
          margin-bottom: 20px;
        }

        svg {
          font-size: 21px;
        }

        @include tablet {
          height: 200px;
        }

        @include desktop {
          height: 200px;
        }

        &.primary {
          color: var(--primary);
          border-color: transparentize(#007bff, 0.7);
        }
        &.secondary {
          color: var(--secondary);
          border-color: transparentize(#6c757d, 0.7);
        }
        &.success {
          color: var(--success);
          border-color: transparentize(#28a745, 0.7);
        }
        &.warning {
          color: var(--warning);
          border-color: transparentize(#ffc107, 0.7);
        }
        &.danger {
          color: var(--danger);
          border-color: transparentize(#dc3545, 0.7);
        }
        &.info {
          color: var(--info);
          border-color: transparentize(#17a2b8, 0.7);
        }
      }
    }
  }

  &__sheet {
    display: flex;
    flex-direction: row;
  }
  &__info {
    &__tab {
      display: flex;
      justify-content: center;
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
      background-color: var(--gray-lighter);
      width: fit-content;
      margin: 0 auto 20px auto;
      border-radius: 50px;

      &__title {
        padding: 9px 15px;
        border-radius: 50px;
        cursor: pointer;

        &.active {
          background-color: var(--white);
          color: var(--green-dark);
        }
      }
    }
    &__content {
      display: flex;

      &__start {
        width: 65%;
      }
      &__start_mobile {
        width: 100%;
      }

      &__end {
        margin-left: 0;
        width: 35%;

        img {
          width: 100%;
          height: auto;

          @include tablet {
            width: 50%;
            height: auto;
          }
        }

        hr {
          margin: 15px auto;
          height: 2px;
          border: none;
        }

        @include desktop {
          margin-left: 20px;
        }
      }
      &__end_mobile {
        margin-left: 0;
        width: 100%;

        img {
          width: 100%;
          height: auto;

          @include tablet {
            width: 50%;
            height: auto;
          }
        }

        hr {
          margin: 15px auto;
          height: 2px;
          border: none;
        }

        @include desktop {
          margin-left: 20px;
        }
      }
    }
  }
}

.left {
  grid-area: left;
  background-color: beige;
  background-size: cover;
  display: flex;
  flex: 1 1 60%;
  margin: 0 0 10px 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  svg {
    transform: scale(2);
  }

  @include desktop {
    margin: 0 20px 0 0;
    flex: 1 1 60%;
  }
}
.right {
  grid-area: right;
  flex: 1 1 40%;
  @include desktop {
    flex: 1 1 40%;
  }
}

.Product_infos {
  border: 1px solid #18294366;
  border-radius: 15px;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ProductInfo {
  flex: 1 1 20%;
  margin: 20px 0 0 20px;
}
.BlockTitle {
  width: inherit;
}

.ProductIcon {
  width: auto;
}
.Product__card {
  position: relative;
}
.Refresh_icon {
  cursor: pointer;
  position: absolute !important;
  top: 8px;
  right: 194px;
  font-size: 18px;
}
.product_sheet_preview_pointer {
  height: 280px;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    // transform: scale(2);
  }
}

.product_sheet_preview_no_pointer {
  height: 280px;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    // transform: scale(2);
  }
}
.product_sheet_image {
  width: 100%;
  height: 70px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  // display: flex;
  margin-bottom: 20px;
  position: relative;
  border-radius: 15px;
  display: inline-block;
  height: 80px;
  width: 150px;
  margin-right: 10px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &:last-child {
    margin-right: 0 !important;
  }
}

.product_sheet_image_event {
  background-size: cover;
  background-position: center;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  border-radius: 15px;
  display: inline-block;
  height: 60px;
  width: 100px;
  margin-right: 0px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-right: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  &:last-child {
    margin-right: 0 !important;
  }
}

.hide_scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

.product_sheet_image::before {
  content: '';
  border-radius: 15px;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.2;
}
.product_sheet_more {
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  width: 100%;
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
  z-index: 1;
}
