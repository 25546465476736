/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.Equipments {
  // margin: 10px;

  @include desktop {
    width: 100%;
  }

  &__block {
    &__3d {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include desktop {
        flex-direction: row;
      }
      & > .EquipmentDetail {
        width: 100%;
        @include desktop {
          width: 50%;
        }
      }
      &__viewer {
        width: 100%;
        min-height: 400px;
        @include desktop {
          width: 50%;
          max-height: 650px;
        }

        & > .view3d__container {
          min-height: 400px;
          height: 100%;

          .matterportViewer__container {
            min-height: 400px;

            & > iframe {
              min-height: 400px;
            }
          }
        }
      }
    }
  }
  &__top {
    display: flex;
    color: #062746;
    text-align: right;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;

    @include desktop {
      flex-direction: row;
    }
    .Breadcrumb {
      margin-bottom: 10px;

      @include desktop {
        margin-bottom: inherit;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-align: left;
      white-space: nowrap;
    }

    span {
      color: black;
      text-align: left;
      align-self: center;
      cursor: initial;
      @include desktop {
        display: block;
      }
    }
    svg {
      font-size: 16px;
      margin-bottom: -3px;
      margin-right: 3px;
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .MuiToggleButtonGroup-root {
        margin-right: 10px;
        width: 300px !important;
        & button:first-child {
          text-transform: capitalize !important;
          width: 50% !important;
          border-radius: 40px !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
        & button:last-child {
          text-transform: capitalize !important;
          width: 50% !important;
          border-radius: 40px !important;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          z-index: 1;
          margin-right: -10px;
        }

        & svg {
          margin-right: 10px;
        }
      }
    }
    & > .Breadcrumb {
      cursor: pointer;
      display: flex;
    }

    &__add {
      margin-right: 20px;
      cursor: pointer;
      text-align: center;

      @include desktop {
        text-align: inherit;
      }
    }
  }
}
