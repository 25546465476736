/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.AuthHandle {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 50% bottom 50%;
    width: 100%;
}
