/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.view3d__container {
  width: 100%;
  height: 75vh;
}
.view3d__mousescroll {
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
