/*** Not Inject ***/
@import '../../styles/variables';

/* Import Vendor */
@import '../../styles/vendor/bootstrap';

/* Import Base */
@import '../../styles/base/mixins/mixin';
@import '../../styles/base/global';
@import '../../styles/base/fonts';
@import '../../styles/base/states';
@import '../../styles/base/utilities';

/* Import Components */
@import '../../styles/components/logo.component';

.Layout {
  //margin-top: 62px;
  //height: 100%;
  @include desktop {
    margin-top: 0;
  }
  &__container {
    // display: flex;
    //max-width: 1400px;
    margin: 0 auto;

    @include desktop {
      height: calc(100% - 62px);
    }
  }
  &__container_login {
    // display: flex;
    max-width: 1400px;
    margin: 0 auto;
    background-image: 'url("/static/media/Valley-10s-1440px.ec244d3e.svg")';
    background-size: 'cover';

    @include desktop {
      height: calc(100% - 62px);
    }
  }
}
