/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.current-circle {
  background: #062746;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  color: white;
}

.todo-circle {
  border: 1px #062746 solid;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  color: #062746;
}

.step-title {
  height: 40px;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: end;
  color: #062746;
  padding-right: 15px;
}

.line {
  border-left: 1px solid #062746;
  width: 1px;
  height: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}
