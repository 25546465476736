$widthMobile: 1px;
$widthTablet: 768px;
$widthDesktop: 1024px;
$widthLargeDesktop: 1280px;

@mixin mobile {
  @media (min-width: $widthMobile) and (max-width: ($widthTablet - 1)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $widthTablet) and (max-width: ($widthDesktop - 1)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $widthDesktop) {
    @content;
  }
}

@mixin only-mobile {
  @include desktop {
    display: none !important;
  }
}

@mixin only-desktop {
  @media (min-width: $widthDesktop) and (max-width: ($widthLargeDesktop - 1)) {
    @content;
  }
}

@mixin truncate($lines-to-show) {
  display: -webkit-box;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
