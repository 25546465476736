/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.MuiDataGrid-root {
  border-color: transparent !important;
  border: 0px !important;
}

.MuiDataGrid-main {
  border-bottom: 1px solid #e0e0e0 !important;
  max-width: calc(100% - 2px);
}

.StatesList .MuiDataGrid-footerContainer {
  border: 0px;
}

.StatesList .MuiDataGrid-columnHeaders {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border: 1px solid #e0e0e0 !important;
  border-bottom: 0px !important;
}

.hidescrollbar {
  overflow: 'scroll';
  height: '100%';
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.StatesList .MuiDataGrid-virtualScroller {
  border: 1px solid #e0e0e0 !important;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.StatesList .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none;
}

.StatesList .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  display: none;
}

.StatesList .MuiDataGrid-row:nth-child(even) {
  background-color: #fbfbfb;
}
.StatesList .cibel-app-theme--header {
  background-color: #fff7f3;
  border-color: #fff7f3;
  height: 76px !important;
}

.StatesList .MuiDataGrid-columnHeaderTitle {
  font-size: 14px !important;
  font-weight: normal !important;
}
.StatesList .MuiDataGrid-columnSeparator {
  color: transparent !important;
}
.StatesList .MuiDataGrid-cell {
  border-color: transparent !important;
  align-items: flex-start !important;
}
.StatesList .MuiChip-root svg {
  font-size: 14px !important;
}
