/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.link-active {
  font-weight: 700 !important;
}
.Header {
  position: fixed;
  z-index: 99;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8edf2;
  box-shadow: none !important;

  @include desktop {
    position: initial;
  }

  &__burger {
    display: grid;
    cursor: pointer;

    svg {
      color: var(--success);
      font-size: 40px;
    }
    span {
      font-size: 12px;
      text-align: center;
      margin-top: -8px;
    }
  }

  &__organisation_name {
    padding: 5px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    height: 100dvh;
    top: 62px;
    left: 0;
    width: 270px;
    background-color: var(--gray-lighter);
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
    transition: all ease-in 400ms;
    //z-index: 1;

    a {
      position: relative;
      padding: 15px 10px;
      color: var(--gray-dark) !important;
      &:after {
        content: '';
        position: absolute;
        width: 0%;
        height: 4px;
        display: block;
        transition: all 0.3s ease;
        bottom: -8px;
      }
      &:hover {
        background-color: var(--gray-lighter);
        font-weight: bold;
        border-left: 5px solid var(--success);
        transition: all ease-in 200ms;
        padding: 15px 15px;
      }
    }
  }

  &__logo {
    // cursor: pointer;
    margin-left: 20px;

    img {
      height: 36px;
      width: auto;
    }
  }

  &__account {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 70px;
    border: 1px solid var(--gray-light);
    padding: 3px;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
    }

    &__icon {
      svg {
        font-size: 21px;
        margin-left: 5px;
      }
    }
    &__picto {
      svg {
        font-size: 33px;
        color: var(--gray-light);
      }
    }

    &__list {
      position: absolute;
      z-index: 100;
      display: grid;
      width: 210px;
      background-color: var(--white);
      top: 56px;
      right: 0px;
      border-radius: 3px;
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);

      a {
        padding: 15px 10px;
        color: var(--gray-dark);
        &:hover {
          background-color: var(--gray-lighter);
          font-weight: bold;
          transition: ease-in 200ms;
        }
      }

      &::before {
        content: '';
        position: absolute;
        transform: rotate(180deg);
        right: 30px;
        top: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--white);
      }
    }
  }
}
