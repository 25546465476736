/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

$height: 20px;

$heightCustomBase: 12px;

@mixin EnergyRow($width, $color, $radius, $heightCustom) {
  position: relative;
  color: var(--white);
  font-weight: bold;
  width: $width;
  height: $heightCustom;
  background-color: $color;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    @if $radius {
      right: -9px;
      top: 0;
      border-radius: 0 50px 50px 0;
      background-color: $color;
      width: 10px;
      height: $heightCustom;
    } @else {
      right: -.49em;
      top: 4px;
      width: 0;
      height: 0;
      transform: rotate(-90deg);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 4px solid $color;
    }
  }
  &.active {
    display: flex;
    align-items: center;
    @if $radius {
      height: 30px;
    } @else {
      border-left: 1px solid black;
      height: $height * 1.5;
    }

    &::after {
      @if $radius {
        height: 30px;
        width: 15px;
        right: -13px;
        top: 0;
      } @else {
        right: -20px;
        top: 10px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 10px solid $color;
      }
    }
  }
}

.EnergyClass {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  span {
    font-size: 8px;
  }

  @include desktop {
    flex-direction: row;
    align-items: center;
  }

  @include tablet {
    align-items: left;
  }

  @include mobile {
    align-items: left;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__energy {
    @include desktop {
      min-width: 160px;
    }

    & > div {
      margin: 2px 0;
      padding-left: 3px;
    }

    label {
      padding-left: 2px;
      font-size: 10px;
      position: absolute;
      text-shadow: 0px 0px 0px var(--black);
      top: 0;
    }

    &__a {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(20px, #4cab83!important, false, $heightCustomBase);
    }

    &__b {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(29px, #6eba72!important, false, $heightCustomBase);
    }

    &__c {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(38px, #87c18b!important, false, $heightCustomBase);
    }

    &__d {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(47px, #efe95d!important, false, $heightCustomBase);
    }

    &__e {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(56px, #e9b84a!important, false, $heightCustomBase);
    }

    &__f {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(65px, #e48e54!important, false, $heightCustomBase);
    }

    &__g {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(74px, #cc3f3a!important, false, $heightCustomBase);
    }

    &__active {
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      height: $height * 1.5;
      width: 70px;

      border: 1px solid black;
      border-right: none;
      box-sizing: border-box;
      border-radius: 3px 0px 0px 3px;
      font-size: 13px;
      font-weight: bold;
      line-height: 10px;

      &__ergy {
        border-right: 1px solid var(--black);
        padding-top: 4px;
        padding-right: 2px;
        span {
          font-weight: normal;
          font-size: 7px;
        }
      }
      &__co2 {
        padding-top: 4px;
        padding-right: 1px;
        padding-left: 1px;
        span {
          font-weight: normal;
          font-size: 7px;
        }
      }
    }
  }
  &__co2 {
    border: 1px solid #a6d9f5;
    scale: 75%;
    transform-origin: top left;
    padding: 4px;
    border-radius: 10px;
    min-width: 147px;
    @include desktop {
      width: 40%;
    }

    label {
      padding-left: 2px;
      font-size: 10px;
      position: absolute;
      text-shadow: 0px 0px 0px var(--black);
      top: 0;
    }

    & > div {
      margin: 2px 0;
      padding-left: 3px;
    }

    &__a {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(20px, #a6d9f5!important, true, $heightCustomBase);
    }

    &__b {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(29px, #88aecc!important, true, $heightCustomBase);
    }

    &__c {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(38px, #758eab!important, true, $heightCustomBase);
    }

    &__d {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(47px, #494f6f!important, true, $heightCustomBase);
    }

    &__e {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(56px, #494f70!important, true, $heightCustomBase);
    }

    &__f {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(65px, #363555!important, true, $heightCustomBase);
    }

    &__g {
      &.active {
        label {
          text-shadow:
            -1px 0 black,
            0 1px black,
            1px 0 black,
            0 -1px black;
          font-size: 26px;
        }
      }
      @include EnergyRow(74px, #21143d!important, true, $heightCustomBase);
    }

    &__active {
      position: relative;
      margin-left: 28px;
      font-size: 13px;
      font-weight: bold;
      line-height: 10px;
      span {
        font-size: 7px;
        font-weight: normal;
      }

      &::before {
        content: '';
        position: absolute;
        top: 13px;
        left: -12px;
        width: 8px;
        height: 2px;
        background-color: var(--black);
      }
    }
  }
}

.EnergyClass__co2 {
  @include tablet {
    margin-left: 62px;
  }

  @include mobile {
    margin-left: 62px;
  }
}
