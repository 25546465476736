/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.MuiDataGrid-root {
  border-color: transparent !important;
  border: 0px !important;
}

.MuiDataGrid-main {
  max-width: calc(100% - 2px);
}

.DocumentsList .MuiDataGrid-footerContainer {
  border: 0px;
}

.DocumentsList .MuiDataGrid-columnHeaders {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom: 0px !important;
}

.hidescrollbar {
  overflow: 'scroll';
  height: '100%';
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.centeredCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DocumentsList .MuiDataGrid-virtualScroller {
  border: 1px solid #e0e0e0 !important;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-radius: 20px 20px 0px 0px;
}

.DocumentsList .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none;
}

.DocumentsList .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  display: none;
}

.DocumentsList .MuiDataGrid-row:nth-child(even) {
  background-color: #fbfbfb;
}
.DocumentsList .cibel-app-theme--header {
  background-color: #1829431c;
  border-color: #1829431c;
  height: 76px !important;
}

.DocumentsList .MuiDataGrid-columnHeaderTitle {
  font-size: 14px !important;
  font-weight: normal !important;
}
.DocumentsList .MuiDataGrid-columnSeparator {
  color: transparent !important;
}
.DocumentsList .MuiDataGrid-cell {
  border-color: transparent !important;
  align-items: flex-start !important;
}
.DocumentsList .MuiChip-root svg {
  font-size: 14px !important;
}
