/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.EquipmentsList {
  display: flex;
  // margin-top: 40px;
  width: 100%;
  flex-direction: column;

  @include desktop {
    width: inherit;
    flex-direction: row;
  }

  &__formWithinEvent {
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__form {
    width: 100%;
    @include desktop {
      width: 30%;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    &__group {
      &__item {
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        border: 1px solid #dedede;
        border-bottom: none;
        margin-left: 0 !important;
        margin-right: 0 !important;

        & > span > span {
          display: flex;
          align-items: center;

          & > svg {
            margin-right: 10px;
            &:hover {
              color: #062746;
            }
          }
        }

        &:last-child {
          border-bottom: 1px solid #dedede;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__add {
    display: flex;
    color: #062746;
    text-align: right;
    border-top: none;
    cursor: pointer;
    align-items: center;

    span {
      @include desktop {
        display: block;
      }
    }

    svg {
      font-size: 16px;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }

  .MuiListItemButton-root {
    &:hover,
    &:active,
    & .selected {
      font-weight: bold;
      color: #062746;
      background: #f1f8ff;
    }
  }
}
