/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.Events {
  width: 100%;
  margin: auto 0px;

  &__document {
    position: relative;
    // display: flex;
    // align-items: center;
    top: -5px;
    // border: 1px solid var(--gray-light);
    // font-size: 14px;
    // height: 50px;
    padding: 1px;
    margin-top: 2px;
    border-radius: 5px;
    cursor: pointer;

    // svg {
    //   position: absolute;
    //   right: 0;
    //   transform: translate(-50%, 0);
    // }
  }

  &__action {
    display: flex;
    justify-content: space-around;
    width: 100%;

    svg {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  &__detail-event {
    // margin-top: 30px;
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 20px;
    margin: 35px;
    // background-color: red;
    // margin-bottom: 20px;
  }
  &__detail-contact {
    margin-top: 20px;
    // box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 8%);
    // border-radius: 5px;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  &__item {
    margin-top: 10px;
  }

  &__lineBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    margin: 30px 0 10px 0;

    @include desktop {
      font-size: 14px;
    }

    &__title {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__form {
    &__subtitle {
      color: var(--green-dark);
      margin: 20px 0;
    }
    &__action {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include desktop {
        flex-direction: row;
        justify-content: space-between;
      }

      &__upload {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px dashed var(--gray-light);
        font-size: 14px;
        height: 50px;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;

        svg {
          position: absolute;
          right: 0;
          transform: translate(-50%, 0);
        }
      }
      &__uploadMini {
        position: relative;
        display: flex;
        align-items: center;
        top: 40px;
        border: 0px dashed var(--gray-light);
        // font-size: 14px;
        height: 0px;
        // padding: 10px;
        // border-radius: 5px;
        cursor: pointer;

        // svg {
        //   position: absolute;
        //   right: 0;
        //   transform: translate(-50%, 0);
        // }
      }

      &__document {
        // position: relative;
        // display: flex;
        // align-items: center;
        border: 1px solid var(--gray-light);
        // font-size: 14px;
        // height: 50px;
        padding: 1px;
        margin-top: 2px;
        // border-radius: 5px;
        cursor: pointer;

        // svg {
        //   position: absolute;
        //   right: 0;
        //   transform: translate(-50%, 0);
        // }
      }
      &__localization {
        @include desktop {
          height: 40px;
        }
        svg {
          height: 15px;
        }
      }
    }
  }
  &__link {
    display: flex;
    color: #062746;
    text-align: right;
    cursor: pointer;
    span {
      display: none;
      @include desktop {
        display: block;
      }
    }
    svg {
      font-size: 16px;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }
}

.Refresh__link svg {
  font-size: 18px;
  margin-right: 15px;
}
