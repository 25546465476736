/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.Attribution {
  display: flex;
  align-items: center;
  width: 100%;
  // position: relative;
  height: 100%;
  flex-direction: column;
  max-width: 1025px;
  margin: 0 auto;
  // margin: 20px auto;
  // margin-right: 20px;
  // margin: 0 20px;

  &__cards {
    // display: flex;
    // flex-wrap: wrap;
    overflow: auto;
    // width: 100%;
    max-width: 1000px;
    padding: 20px;
    // padding-right: 30px;

    // margin-bottom: 65px;
    // margin-left: 25px;
    // justify-content: center;

    // @include desktop {
    //   justify-content: initial;
    // }

    & > div {
      margin-top: 20px;

      width: 100%;

      // @include desktop {
      //   width: 50%;
      // }
    }
  }

  &__product {
    // max-width: 1200px;
    overflow: hidden;
    // background-color: antiquewhite;
    // border: 1px solid #ccc;
    border-radius: 5px;
    // padding: 0 20px;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease-out;
    // padding-bottom: 15px;
  }

  &__product-card__image {
    min-height: 100px;
    // padding-bottom: 15px;
  }

  &__product-card__info {
    padding-left: 15px;
    // padding-bottom: 25px;
  }

  &__container {
    max-width: 1025px;
    // max-width: 1200px;
    // margin: 0 auto;
    // width: 100%;
  }
  &__sub_container {
    max-width: 1025px;
    margin: 0 auto;
    padding-top: 20px;
    padding: 0 20px;
    // width: 100%;
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }

  // &__subtitle {
  //   color: var(--green-dark);
  //   margin: 20px 0;
  //   font-weight: bold;
  // }
  // &__lineBlock {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   font-size: 13px;
  //   color: var(--black);

  //   @include desktop {
  //     font-size: 14px;
  //   }
  // }
  // &__userBox {
  //   display: flex;
  //   align-items: center;
  //   margin: 10px 0;
  // }

  // &__row {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }

  // &__link {
  //   display: flex;
  //   color: var(--green-dark);
  //   text-align: right;
  //   cursor: pointer;
  //   span {
  //     display: none;
  //     @include desktop {
  //       display: block;
  //     }
  //   }
  //   svg {
  //     font-size: 16px;
  //     margin-bottom: -3px;
  //     margin-right: 3px;
  //   }
  // }
}
