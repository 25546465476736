/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.Wizzards {
  &__container {
    background-color: #062746;
  }
  &__content {
    // background-color: #062746;
    height: 100%;
  }
  &__form_item {
    padding-bottom: 30px;
  }

  &__title_bar {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #062746;
    text-align: right;
    width: 100%;
    position: relative;
    top: -5px;

    @include tablet {
      font-size: 16px;
      line-height: 22px;
    }

    @include desktop {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__title_bar_container {
    display: flex;
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #fff7f3;
  }
  &__contentTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: #062746;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__center {
    text-align: center;
  }
  &__checkbox_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #062746;
  }
  &__title {
    // width: 299px;
    height: 46px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    color: #062746;

    @include tablet {
      font-size: 16px;
      line-height: 22px;
    }

    @include desktop {
      font-size: 20px;
      line-height: 22px;
    }
  }
  &__panel_subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #062746;
    padding-bottom: 20px;
  }
  &__infoItemTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    color: #929292;
  }

  &__dropZoneArea {
    width: 400px;
    margin: auto;
  }

  &__infoItemValue {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #062746;
    padding-bottom: 5px;
  }
}

// display none for the sidebar bookmark and attachment tabs

#rpv-default-layout__sidebar-tab-1 {
  display: none;
}

#rpv-default-layout__sidebar-tab-2 {
  display: none;
}

.rpv-core__display--block-small {
  display: none !important;
}

.rpv-open__input-wrapper{
  display: none !important;
}

.hide_scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}