/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.matterportViewer__container {
  height: calc(100%);
  width: 100%;
  border-radius: 20px;
  .placeBtn__container {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    margin: 10px;
    .placeBtn {
      transition: transform 0.2s ease-out;

      &__pinned {
        transition: transform 0.2s ease-out;
        transform: rotate(45deg);
      }
    }
  }

  .tips__container {
    position: absolute;
    bottom: 1.2rem;
    margin-left: 15px;
  }

  iframe {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  #AU_Helper {
    width: 315px;
  } /* show it on small screens */
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
  #AU_Helper {
    width: 545px;
  } /* hide it elsewhere */
}

.matterport-showcase iframe {
  min-height: 240px;
  margin: 16px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .matterport-showcase iframe {
    min-height: 360px;
  }
}

@media screen and (min-width: 992px) {
  .matterport-showcase iframe {
    height: 600px;
    max-width: 1068px;
    margin: 0 auto 15px;
  }
}

.matterport-showcase {
  text-align: center;
}
