/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.ProductList {
  &__link {
    display: flex;
    text-align: right;
    cursor: pointer;
    span {
      display: none;
      @include desktop {
        display: block;
      }
    }
    svg {
      font-size: 16px;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }

  @include desktop {
    flex-direction: row;
    overflow: hidden;
  }

  &__error {
    text-align: center;
    width: 50%;
  }

  &__loader {
    width: 50%;
    margin: 0 auto;
  }

  &__left {
    display: none;
    //display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;

    @include desktop {
      width: 70%;
      height: fit-content;
      max-height: 100dvh;
      position: absolute;
      top: 0;
    }

    &__title {
      font-weight: bold;
      margin: 0 20px;
    }

    &__checkbox {
      margin: 20px 0;

      &__items {
        display: flex;
        margin: 0 20px;

        & > label {
          display: flex;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }

    h1 {
      margin: 20px;
    }

    &__search {
      padding: 20px;
    }

    &__select {
      margin: 0 auto;
      margin-bottom: 10px;

      @include desktop {
        margin-left: 20px;
      }
    }

    &__list {
      padding: 20px;
      overflow: scroll;
    }

    &__cards {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      margin-bottom: 65px;
      margin-left: 10px;
      justify-content: center;

      // @include desktop {
      //   justify-content: initial;
      // }

      & > div {
        margin: 10px;
        width: 100%;

        @include desktop {
          width: 50%;
        }
      }
    }
  }

  &__map {
    display: none;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    transition: width 0.2s ease 0s;
    animation-timing-function: ease;
    height: 400px;
    width: 100%;

    @include desktop {
      height: 100%;
      width: 30%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &__button {
      position: absolute !important;
      top: 50%;
      border: 1 px solid rgba(1, 1, 1, 0.1);
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.05) !important;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
      }
    }
  }
}
.CibelSearchField {
  background: #fafcff;

  & .MuiOutlinedInput-root {
    padding-left: 0;
    & fieldset {
      border-color: #eef3f7;
      border-radius: 21px;
    }
    &:focus fieldset,
    &:hover fieldset {
      border: 1px solid #062746 !important;
    }
    &.Mui-focused fieldset {
      border: 1px solid #062746;
    }
  }
}

.pac-container {
  position: absolute;
  box-shadow: none;
  border: solid 2px #062746;
  border-top: 0px;
  border-radius: 5px;
  top: -5px;
  z-index: 9999;
}

.pac-icon {
  display: none;
}

.pac-item {
  padding-left: 13px;
  font-family: Roboto;
  font-size: 14px;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 400;
}
