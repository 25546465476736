/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.AllEvents {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100%;
  flex-direction: column-reverse;

  @include desktop {
    flex-direction: row;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;

    @include desktop {
      width: 100%;
      height: fit-content;
      max-height: 100dvh;
      position: absolute;
      top: 0;
    }

    &__list {
      padding: 20px;
      overflow: scroll;
    }

    &__cards {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      margin-bottom: 65px;
      margin-left: 10px;
      justify-content: center;

      // @include desktop {
      //   justify-content: initial;
      // }

      & > div {
        margin: 10px;
        width: 100%;

        @include desktop {
          width: 100%;
        }
      }
    }
  }

  &__left_selected {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;

    @include desktop {
      width: 70%;
      height: fit-content;
      max-height: 100dvh;
      position: absolute;
      top: 0;
    }

    &__cards {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      margin-bottom: 65px;
      margin-left: 10px;
      justify-content: center;

      // @include desktop {
      //   justify-content: initial;
      // }
    }
  }

  &__right {
    overflow: scroll;
    background-size: contain;
    background-repeat: no-repeat;
    // transition: width 0.2s ease 0s;
    // animation-timing-function: ease;
    height: 400px;
    width: 100%;

    @include desktop {
      height: 100%;
      width: 30%;
      position: fixed;
      top: 0;
      right: 0;
    }
  }

  // &__eventsLists {
  //   @include only-desktop {
  //     -webkit-box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.08);
  //     box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.08);
  //     position: fixed;
  //     height: calc(100% - 62px);
  //     overflow: scroll;
  //     font-size: 14px;
  //   }
  // }

  // &__eventsLists_eventSelected {
  //   @include only-desktop {
  //     -webkit-box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.08);
  //     box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.08);
  //     position: fixed;
  //     height: calc(100% - 62px);
  //     overflow: scroll;
  //     max-width: 920px;
  //     font-size: 14px;
  //   }
  // }
}

.Refresh__link svg {
  font-size: 18px;
  margin-right: 15px;
}
