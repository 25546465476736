/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.AdemeSearch {
  width: 100%;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  &__title {
    margin: 25px 0;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__subtitle {
    color: var(--green-dark);
    margin: 20px 0;
    font-weight: bold;
  }

  &__userBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    display: flex;
    color: var(--green-dark);
    text-align: right;
    cursor: pointer;
    span {
      display: none;
      @include desktop {
        display: block;
      }
    }
    svg {
      font-size: 16px;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }
}
