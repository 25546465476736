/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.BasicModal {
  position: relative;
  padding: 0px !important;
  max-height: 100dvh;

  &__close {
    position: fixed;
    display: flex;
    align-items: center;
    top: 10px;
    right: 10px;
    color: rgb(1, 17, 66);
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    font-size: 13px;
    &:hover {
      color: rgba(255, 85, 0, 1);
      border: 1px solid rgba(255, 85, 0, 1);
    }
    z-index: 3;
  }
  &__content {
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    padding: 0px !important;
    background-color: rgba(159, 34, 34, 0.5);
    max-height: 100dvh;
    @include desktop {
      padding: 0px;
    }
  }
  &_content::-webkit-scrollbar {
    display: none;
  }
}
